<template>
  <div class='main'>
    <TitleBlock/>
    <HeaderBlock/>
    <DescriptionBlock/>

    <VoiceBlock voice="人材にゆとりをつくり、クライアントから<br>より多くの依頼を受注すべく、flickの導入を決意"/>
    <InterviewBlock interview="
      <p class='huge3-label'><span class='dash-icon'></span>構造設計部で注力している業務について聞かせてください。</p>
      <br>
      <p><span class='name-dm'>田村：</span>一般建築物の構造設計業務、鉄骨造、RC造、木造と種別を問わず構造設計を行っていますが、最近はこの中でも鉄骨造や木造の建物を多く設計しています。また、自社開発のプログラムを活かした 構造設計のコンピュテーショナルデザイン利用や、構造BIMモデルの作成にも注力しています。</p>
      <br>
      <p class='huge3-label'><span class='dash-icon'></span>建築物の構造設計は、中小の意匠事務所からの依頼が多いのでしょうか。</p>
      <br>
      <p><span class='name-dm'>田村：</span>弊社に構造設計を依頼する企業は、意匠系の建築設計事務所に加えて、建設会社の方も いらっしゃいます。特に、林業の強みを生かした地域に根ざす木造建築物の依頼が増えてきています。</p>
      <br>
      <p class='huge3-label'><span class='dash-icon'></span>構造設計からBIMモデル作成まで幅広い業務を抱えていらっしゃいますが、そのような 業務の中で、flickを導入した経緯や決め手を教えてください。</p>
      <br>
      <p><span class='name-dm'>田村：</span>flickの導入は、フルーさんのご提案がきっかけです。人手不足で作図や構造計算の特定の業務に関しては、<span class='background_orange-label'>協力会社にお願いをすることがあります。しかし、その都度業務の内容をまとめて委託書や仕様書などを作成していると、時間がかかり範囲外の業務まで対応していただくことが難しくなります。</span></p>
      <br>
      <p>従来のやり方であれば、フレキシブルに対応ができませんが、flickならコンシェルジュに依頼をすれば、相談を聞いていただいたり業務を受けていただいたりできます。</p>
      <br>
      <p>このようなクラウドソーシングサービスに興味がありました。<span class='background_orange-label'>時間内でどの業務を依頼すればよいのかを計画しやすい点はメリットがあり、費用対効果は大きいと思います。</span></p>
    "/>

    <VoiceBlock voice="通常では確保しづらい、高いスキルをもつ人材を<br>手配できるのがflickだった"/>
    <InterviewBlock interview="
      <p class='huge3-label'><span class='dash-icon'></span>従来の外注スタイルとflickの違いを教えてください。</p>
      <br>
      <p><span class='name-dm'>田村：</span>flickは契約時間内であれば、細かな業務や設計に付随する業務を自由に依頼できます。たとえば、構造設計業務では、メインの業務以外にも二次部材の検討などの業務が発生します。このような細かい作業を、小分けして発注できるところはflickのメリットです。</p>
      <br>
      <p class='huge3-label'><span class='dash-icon'></span>flickを活用するなかで感じたメリットはありますか。</p>
      <br>
      <p><span class='name-dm'>田村：</span>業務の依頼を出してから対応できる人員を確保するまでには、時間がかかります。その点<span class='background_orange-label'>flickは、相談をして数日でスキルをもつ人材を手配していただけるため、人材確保にかかる時間や手間を短縮できることが大きなメリットです。</span></p>
      <br>
      <p>flickには、<span class='background_orange-label'>弊社と作業者の橋渡しをする「コンシェルジュ」がいます。個々の作業者に連絡を取って指示を出す必要は無く、依頼や相談はすべてコンシェルジュに対応いただいています。</span></p>
      <br>
      <p>コンシェルジュは橋渡しをするだけではなく、構造設計の知識もあるため業務の話をすぐに汲み取って いただけることがとても助かっています。</p>
      <br>
      <p>このコンシェルジュがいることで、新たな業務をお願いするときや小さな相談も気兼ねなく話せています。</p>
      <br>
      <p class='huge3-label'><span class='dash-icon'></span>flickを使うことで課題をどのように解決できましたか。</p>
      <br>
      <p><span class='name-dm'>田村：</span>先日、クライアントから大きな依頼を受注した際にもflickのコンシェルジュに相談をして作業者の 数を増やし、納期に間に合うよう業務に対応していただきました。</p>
      <br>
      <p><span class='background_orange-label'>必要に応じて作業者の数を調整できるため、クライアントに突発的な作業や大量の作業を依頼されても、 自分たちはコア業務に専念できます。</span></p>
      <br>
      <p class='huge3-label'><span class='dash-icon'></span>flickに依頼をしてよかった点はありますか。</p>
      <br>
      <p><span class='name-dm'>田村：</span>業務の作業量をコントロールできる点は、とても助かっています。最小限の人数で対応している 関係上、予測がつかない作業が発生すると忙しくなります。社員の長時間労働を減らし、体調不良のリスクも軽減できます。</p>
      <br>
      <p class='huge3-label'><span class='dash-icon'></span>課題の解決以外にも、flickの活用でどのような効果がありましたか。</p>
      <br>
      <p><span class='name-dm'>田村：</span>協力会社に業務を依頼する場合、依頼している業務の範囲外となれば、その都度契約書を交わす必要があります。flickは範囲外の業務でも包絡的に柔軟に対応していただけるため、書類を作成する手間が省けたと感じました。</p>
      <br>
      <p>またflickからは「業務報告レポート」が送られてきます。これは、作業内容や残り時間を書いた報告書で、 このレポートを見ながら次はどのような業務を依頼すべきか計画を立てられます。</p>
      <br>
      <p>ただ作業を依頼して納品されるだけではなく、こういった報告を随時していただける点はとても便利です。</p>
    "/>

    <img src="@/assets/image/pages/example/dix/report.png" class="base_image"/>

    <VoiceBlock voice="オンラインでやりとりをする不安は解消！<br>橋渡し役のコンシェルジュの対応力がflickのカギとなる"/>
    <InterviewBlock interview="
      <p class='huge3-label'><span class='dash-icon'></span>flickはすべてオンラインでやりとりを行いますが、すぐに馴染めましたか。</p>
      <br>
      <p><span class='name-dm'>田村：</span>flickと弊社のやりとりは、おもにSlackを使用しています。電話よりチャットツールを使用した ほうが、指示漏れがなくなります。Web会議ではZoomも活用しています。</p>
      <br>
      <p>今後、業務内容によっては対面の打ち合わせのほうがよいケースがあるかもしれません。現状では、 さほど問題はありません。Web会議は録画をして、flickのアシスタントに伝えていただいているため、 オンラインのデメリットをうまく解決できています。</p>
      <br>
      <p class='huge3-label'><span class='dash-icon'></span>導入前に不安はありましたか。</p>
      <br>
      <p><span class='name-dm'>田村：</span>以前からチャットツールやウェブ会議を活用して業務を行っていたため、オンラインであることに関して不安はありませんでした。ただし、コンシェルジュと円滑にコミュニケーションができるか、 レスポンスが遅くないか、flickに依頼した業務が想定しているクオリティで納品されてくるかなど不安な点はあったかもしれません。</p>
      <br>
      <p>実際には、チャットツールを利用してやりとりをしていますが、レスポンスの早さやコミュニケーション 能力にも問題はありません。flickから納品されるものは、担当のコンシェルジュが目を通して確認されたうえで納品されるため、不安は解消されています。</p>
      <br>
      <p class='huge3-label'><span class='dash-icon'></span>flickの活用を検討している企業へのメッセージをお願いします。</p>
      <br>
      <p><span class='name-dm'>田村：</span>若い年代の方は構造設計離れをしており、今後は人材不足も加速していくと思います。特に、 小規模の事務所となれば人材の確保が難しくなるはずです。そうであったとしても設計を諦めるのでは なく、flickのようなサービスを活用してみることもよいと思います。</p>
    "/>
  </div>
</template>

<script>
  import Dix from "@/assets/js/pages/example/Dix";
  import TitleBlock from "@/components/organisms/page/example/dix/TitleBlock";
  import InterviewBlock from "@/components/organisms/page/example/dix/InterviewBlock";
  import HeaderBlock from "@/components/organisms/page/example/dix/HeaderBlock";
  import DescriptionBlock from "@/components/organisms/page/example/dix/DescriptionBlock";
  import VoiceBlock from "@/components/organisms/page/example/dix/VoiceBlock";

  export default {
    name: 'Dix',

    components: {
      VoiceBlock,
      DescriptionBlock,
      HeaderBlock,
      InterviewBlock,
      TitleBlock
    },

    mixins: [Dix]
  }
</script>

<style lang="scss" scoped>
  .main {
    > .title-block {
      margin: 3rem 0;
    }
    > .description-block, > .interview-block {
      margin: 6rem 0;
    }
    > .base_image {
      margin: 0 auto 12rem auto;
      width: 80rem;
    }
  }
</style>
