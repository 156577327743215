<template>
  <div class="merits-block">
    <div class="contents">
      <h2 class="rectangle_label">メリット</h2>
      <p class="orange-label">必要な時だけ、必要なスキルを持った人材が手に入る</p>
      <img class='base_image' slot='image' src='@/assets//image/pages/top/merits.png'>

      <p class="orange-label">働き方改革と生産性向上</p>
      <div>
        <div class="left">
          <p class="orange-label">ITツール導入の次の一手は？</p>
          <P>近年、働き方改革と生産性向上のためにITツールを導入し、業務効率化を進める企業は多いかと思います。</P>
          <P>しかしながら単品生産が基本の建設業界では自動化できない多くの作業があります。</P>
          <P>これらの作業の内、所員でなくてもできる作業をうまく切り出すことができれば、所員が本来やるべき作業に集中できたり、労働時間を短縮することが可能になります。</P>
        </div>

        <div class="right">
          <img class='base_image' slot='image' src='@/assets//image/pages/top/merit1.png'>
        </div>
      </div>

      <div>
        <div class="left">
          <p class="orange-label">優秀なアシスタントをスポットで</p>
          <P>しかし、外注するほどまとまった量の作業ではなかったり、派遣社員を入れるほどコストはかけられないという事情で、切り出せずにいる作業は相当あるのではないでしょうか</P>
          <P>そこでflickを活用すると、必要な時だけ必要なスキルを持った作業者に依頼ができるので、 低コストで手軽に作業を切り出すことができます。</P>
        </div>
        <div class="right">
          <img class='base_image' slot='image' src='@/assets//image/pages/top/merit2.png'>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "MeritsBlock"
  }
</script>

<style lang='scss' scoped>
  .merits-block {
    > .contents {
      @include contents;

      > .rectangle_label {
        margin-bottom: 4rem;
        width: 100%;
      }

      > .base_image {
        margin: 4rem 0;
        width: 90rem;
      }

      > div {
        @include double_columns(70%, 20%);
        justify-content: space-between;
        margin-bottom: 4rem;

        > .left {
          > .orange-label {
            margin-bottom: 4rem;
          }
        }

        > .right {
          display: flex;
          justify-content: center;
          height: 15rem;
        }
      }
      @media screen and (min-width: 768px) {
        > .rectangle_label {
          width: 60%;
        }
      }
    }
  }
</style>