<template>
  <div class='main'>
    <TopBlock/>

    <ServiceBlock id="service"/>
    <WorkBlock/>
    <MeritsBlock/>
    <FeatureBlock/>

    <DifferenceWorkBlock/>
    <DifferenceStaffBlock/>

    <UsageBlock id="usage"/>
    <MeetingBlock/>
    <PriceBlock id="price"/>
    <DiscountBlock/>
    <ExampleBlock id="example"/>
    <ContactBlock id="contact"/>
  </div>
</template>

<script>
  import Top from "@/assets/js/pages/Top";
  import ServiceBlock from "@/components/organisms/page/top/ServiceBlock";
  import WorkBlock from "@/components/organisms/page/top/WorkBlock";
  import TopBlock from "@/components/organisms/page/top/TopBlock";
  import MeritsBlock from "@/components/organisms/page/top/MeritsBlock";
  import FeatureBlock from "@/components/organisms/page/top/FeatureBlock";
  import UsageBlock from "@/components/organisms/page/top/UsageBlock";
  import MeetingBlock from "@/components/organisms/page/top/MeetingBlock";
  import PriceBlock from "@/components/organisms/page/top/PriceBlock";
  import DiscountBlock from "@/components/organisms/page/top/DiscountBlock";
  import ExampleBlock from "@/components/organisms/page/top/ExampleBlock";
  import DifferenceWorkBlock from "@/components/organisms/page/top/DifferenceWorkBlock";
  import DifferenceStaffBlock from "@/components/organisms/page/top/DifferenceStaffBlock";
  import ContactBlock from "@/components/organisms/page/top/ContactBlock";

  export default {
    name: 'Top',

    components: {
      ContactBlock,
      DifferenceStaffBlock,
      DifferenceWorkBlock,
      DiscountBlock,
      ExampleBlock,
      PriceBlock, MeetingBlock, UsageBlock, FeatureBlock, MeritsBlock, TopBlock, WorkBlock, ServiceBlock
    },

    mixins: [Top]
  }
</script>

<style lang="scss" scoped>
  .main {
    > .top-block {
      height: 20rem;
    }

    > .service-block, > .merits-block, > .usage-block, > .price-block, .difference_work-block, > .example-block, > .contact-block {
      margin: 6rem 0;
    }

    @media screen and (min-width: 768px) {
      > .top-block {
        height: 40rem;
      }
    }
  }
</style>
