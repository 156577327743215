<template>
  <div class="usage-block">
    <div class="contents">
      <h2 class="rectangle_label">プラン・料金</h2>
      <div class="box-dm">
        <HeaderRectangleBox
          header="スタンダード"
          rectangle-description="<p>作業時間 : 30時間 / 月</p>"
          description="
          <p class='blue-label'>【付帯サービス】</p>
          <p>作業に関わる雑費は弊社にて負担 <span class='small1-label'>※1</span></p>
          <p>専門作業チーム編成 <span class='small1-label'>※2</span></p>"
          :is-blue="true"
        />

        <HeaderRectangleBox
          header="ライト"
          rectangle-description="<p>作業時間 : 10時間 / 月</p>"
          description="<p>低予算で業務の切り出しを依頼したい方や試しに利用してみたい方へ</p>"
          :is-blue="false"
        />
      </div>

      <p class="small1-label">※1 郵送代や封筒代・印刷代などの費用。（上限3,000円/月）</p>
      <p class="small1-label">
        ※2 長期間のプロジェクトや秘匿性の高いプロジェクトの際に特定の作業者でチーム編成することで、作図表現をそろえたり情報資産の開示範囲を限定することができます。<br>
        各プランの料金につきましては、作業内容を精査してお見積もりいたします。
        ページ下部の<a class="blue-label" href="/#contact">お問い合わせフォーム</a>よりご連絡ください。
      </p>

      <div class="description-dm">
        <p>【共通事項】</p>
        <p>応対時間 ： 平日  9時～17時 （12時～13時は応対を休止させて頂きます）</p>
        <p>契約期間 ： 6カ月 <span class="orange-label">（解約手数料・違約金不要）</span> ※月ごとの契約本数変更や休止にも柔軟に対応いたします。</p>
        <p>延長料金 ： 上限時間を超過した場合、ご契約の料金に応じた延長料金が発生します。</p>
      </div>
    </div>
  </div>
</template>

<script>
  import HeaderRectangleBox from "@/components/organisms/common/box/HeaderRectangleBox";
  export default {
      name: "PriceBlock",
    components: {HeaderRectangleBox}
  }
</script>


<style lang='scss' scoped>
  .usage-block {
    > .contents {
      @include contents;

      > .rectangle_label {
        margin-bottom: 4rem;
        width: 100%;
      }

      > .box-dm {
        > .header_rectangle-box {
          margin-bottom: 2rem;
        }
      }

      > .small1-label {
        margin: 1rem 0;
      }

      > .description-dm {
        @include round;
        margin: 1rem 0;
        padding: 2rem;
        border: 2px solid $border-color;
      }

      @media screen and (min-width: 768px) {
        > .rectangle_label {
          width: 60%;
        }

        > .box-dm {
          display: flex;
          justify-content: space-between;
          > .header_rectangle-box {
            width: 49%;
          }
        }
      }
    }
  }
</style>
