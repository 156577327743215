<template>
  <div class="header-block">
    <div class="contents">
      <div>
        <img class="left base_image" src='@/assets/image/pages/example/dix/header.png'/>

        <div class="right">
          <img class="base_image" src='@/assets/image/pages/example/dix/logo.png'/>
          <p class="huge3_wide-label">業務コントロールに成功、<br>社員の長時間勤務を防止。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "HeaderBlock",
  }
</script>

<style lang='scss' scoped>
  .header-block {
    background-color: $third-color;

    > .contents {
      @include contents;

      > div {
        @include double_columns(50%, 50%);

        > .right {
          display: flex;
          flex-flow: column;
          margin: 2rem 0 2rem 2rem;

          > .base_image {
            margin-bottom: 2rem;
            width: 40%;
          }
        }
      }

      @media screen and (min-width: 768px) {
        > div {
          > .right {
            margin: 0 0 0 8rem;

            > .base_image {
              margin: 6rem 0 2rem 0;
            }
          }
        }
      }
    }
  }
</style>
