import store from "@/store";

export default {
  head: {
    title: {
      inner: 'フリック',
      separator: '|',
      complement: '建築設計のスーパーアシスタント'
    },
    meta: [
      {name: 'description', content: '作図や計算、整合チェックなど、設計事務所内の雑多な作業を手軽に依頼できる！高スキルのプロ人材に頼めるので安心、しかも必要な時だけ依頼できてリーズナブル！設計者がやるべき仕事に集中できて生産性を飛躍的に高めます。'},
      {property: 'og:description', content: '作図や計算、整合チェックなど、設計事務所内の雑多な作業を手軽に依頼できる！高スキルのプロ人材に頼めるので安心、しかも必要な時だけ依頼できてリーズナブル！設計者がやるべき仕事に集中できて生産性を飛躍的に高めます。'},
    ],
  },

  created() {
    store.dispatch('page/setPageName', 'Top');
  },
}