import Vue from 'vue'
import Router from 'vue-router'
import VueHead from 'vue-head'
import smoothscroll from 'smoothscroll-polyfill';

import MainTemplate from '@/components/_base/MainTemplate'

import Top from '@/components/pages/Top'
import Dix from '@/components/pages/example/Dix'

Vue.use(VueHead);
smoothscroll.polyfill();
Vue.use(Router);

export default new Router({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0}
    }
  },
  routes: [
    {
      path: '/',
      component: MainTemplate,
      children: [
        {
          path: '',
          name: 'Top',
          component: Top,
        },
      ]
    },
    {
      path: '/example',
      component: MainTemplate,
      children: [
        {
          path: 'dix',
          name: 'Dix',
          component: Dix,
        },
      ]
    },
  ]
})
