import store from "@/store";

export default {
  head: {
    title: {
      inner: 'フリック',
      separator: '|',
      complement: '建築設計のスーパーアシスタント'
    },
    meta: [
      {name: 'description', content: 'フリックを活用して業務効率化を進め、売上アップ、業務拡大をしている企業をご紹介。新しい外注形態に踏み出した企業の結果とは？'},
      {property: 'og:description', content: 'フリックを活用して業務効率化を進め、売上アップ、業務拡大をしている企業をご紹介。新しい外注形態に踏み出した企業の結果とは？'},
    ],
  },

  created() {
    store.dispatch('page/setPageName', 'Dix');
  },
}