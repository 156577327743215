<template>
  <div class="inquiry_box">
    <p><span class="strong_label">※</span> お名前</p>
    <input v-model="mail.name" type="text" class="text_form" placeholder="お名前">

    <p>会社名</p>
    <input v-model="mail.office" type="text" class="text_form" placeholder="株式会社〇〇〇〇">

    <p><span class="strong_label">※</span> メールアドレス</p>
    <input v-model="mail.address" type="email" class="text_form" placeholder="flew@example.com">

    <p>電話番号</p>
    <input v-model="mail.phoneNumber" type="text" class="text_form" placeholder="000-0000-0000">

    <p><span class="strong_label">※</span> お問い合わせ内容</p>
    <textarea v-model="mail.text" class="textarea_form"/>
    <button class="active-rectangle_button" @click="sendMail">送信</button>
  </div>
</template>

<script>
  import toast from '@/assets/js/functions/_core/toast';
  import caller from "@/assets/js/functions/firebase/cloud_functions/caller";

  export default {
    name: "InquiryBox",

    mixins: [toast, caller],

    data() {
      return {
        mail: {
          name: '',
          office: '',
          address: '',
          phoneNumber: '',
          category: 'flick',
          text: '',
        }
      }
    },

    methods: {
      isValidInquiry() {
        if (!this.mail.name || !this.mail.address || !this.mail.text) {
          this.showErrorMessage('必須項目が入力されていません。');
          return false;
        }
        return true;
      },

      async sendMail() {
        if (!this.isValidInquiry()) return;

        await this.callFunction(
          'sendInquiryMail',
          {mail: this.mail}
        ).then(() => {
          this.mail.name = '';
          this.mail.office = '';
          this.mail.address = '';
          this.mail.phoneNumber = '';
          this.mail.text = '';
          this.showSuccessMessage('お問い合わせを送信しました。');
        }).catch(() => {
          // Vueでは例外に何もしない（thenの処理を発生させたくないので、例外は投げるが関数側で処理済み）
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .inquiry_box {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    > p {
      margin-top: 1rem;
    }

    > .text_form, > .textarea_form, > .select_form {
      margin: 1rem 0 2rem 0;
    }

    > p, > .text_form, > .textarea_form, > .select_form {
      width: 90%;
    }
  }

  @media screen and (min-width: 768px) {
    .inquiry_box {
      > p, > .text_form, > .textarea_form, > .select_form {
        width: 80%;
      }
    }
  }
</style>