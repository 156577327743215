<template>
  <div class="header_rectangle-box" :class="isBlue ? 'blue' : 'orange'">
    <div class="header-dm" :class="isBlue ? 'blue' : 'orange'">
      <p class="huge3-label">{{header}}</p>
    </div>

    <div class="rectangle-dm" :class="isBlue ? 'blue' : 'orange'" v-html="rectangleDescription"/>

    <div class="description-dm" v-html="description"/>
  </div>
</template>

<script>
  export default {
    name: "HeaderRectangleBox",

    props: {
      header: {
        type: String,
        required: true
      },
      rectangleDescription: {
        type: String,
        required: true
      },
      description: {
        type: String,
        required: true
      },
      isBlue: {
        type: Boolean,
        required: true,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .header_rectangle-box {
    display: flex;
    flex-flow: column;
    &.blue {
      background-color: $primary_accent-color;
    }

    &.orange {
      background-color: $third-color;
    }

    > .header-dm {
      padding: 1rem 0 1rem 2rem;

      &.blue {
        color: $six_accent-color;
        background-color: $fifth_accent-color;
      }

      &.orange {
        color: $primary-color;
        background-color: $fourth-color;
      }
    }

    > .rectangle-dm {
      @include round;

      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      margin: 4rem;
      padding: 1rem 0;
      background-color: $white-color;
      line-height: 4rem;

      &.blue {
        border: 0.5rem solid $six_accent-color;
      }

      &.orange {
        border: 0.5rem solid $primary-color;
      }

      > p {
        width: 80%;
      }
    }

    > .description-dm {
      margin: 2rem 4rem 4rem 4rem;
    }
  }
</style>
