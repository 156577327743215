<template>
  <footer class="footer">
    <p>Copyright © 2021 flew Inc. <br class="pc_none">All rights reserved.</p>
  </footer>
</template>

<script>

  export default {
    name: "Footer",
  }
</script>

<style lang="scss" scoped>
  .footer {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
  }
</style>
