<template>
  <!-- ハンバーガーメニューは実装工数を削減するため、次のURLのものを丸パクリ -->
  <!-- また、クラス名も例外的なので他のコンポーネントと色々違う。時間があれば作り直す。（絶対やらないやつ） -->
  <!-- https://www.nxworld.net/tips/10-css-hamburger-menu-icon-design-and-effect.html -->
  <div
      class="hamburger-menu_button pc_none"
      :class="isMenuOpen ? 'active' : ''"
      @click="toggleActivation()">
    <span></span>
    <span></span>
    <span></span>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import store from "@/store";

  export default {
    name: "HamburgerMenuButton",

    computed: {
      ...mapGetters({isMenuOpen: 'cover/isMenuOpen'}),
    },

    methods: {
      /**
       * ハンバーガーメニューの活性・非活性を切り替える
       */
      toggleActivation() {
        store.dispatch('cover/toggleMenuCover')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .hamburger-menu_button,
  .hamburger-menu_button span {
    display: inline-block;
    transition: all .4s;
    box-sizing: border-box;
    margin-right: 0.5rem;
  }

  .hamburger-menu_button {
    position: relative;
    width: 50px;
    height: 44px;
  }

  .hamburger-menu_button span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background-color: $secondary-color;
  }

  .hamburger-menu_button span:nth-of-type(1) {
    top: 0;
  }

  .hamburger-menu_button span:nth-of-type(2) {
    top: 20px;
  }

  .hamburger-menu_button span:nth-of-type(3) {
    bottom: 0;
  }

  .hamburger-menu_button.active span:nth-of-type(1) {
    width: 45%;
    -webkit-transform: translate3d(2px, 10px, 0) rotate(45deg);
    transform: translate3d(2px, 10px, 0) rotate(45deg);
  }

  .hamburger-menu_button.active span:nth-of-type(2) {
    -webkit-transform: translate3d(-1px, 0, 0) rotate(-45deg);
    transform: translate3d(-1px, 0, 0) rotate(-45deg);
  }

  .hamburger-menu_button.active span:nth-of-type(3) {
    width: 45%;
    -webkit-transform: translate3d(23px, -9px, 0) rotate(45deg);
    transform: translate3d(23px, -9px, 0) rotate(45deg);
  }
</style>
