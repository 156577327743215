<template>
  <div
      class="sp-menu_cover"
      @click="hideMenuCover()"
      :class="isMenuOpen ? 'animated' : ''">

    <ul>
      <template v-if="pageName === 'Top'">
        <li @click="scrollToHash('service')">
          サービス
        </li>
        <li @click="scrollToHash('usage')">
          ご利用方法
        </li>
        <li @click="scrollToHash('price')">
          プラン・料金
        </li>
        <li @click="scrollToHash('example')">
          活用事例
        </li>
        <li @click="scrollToHash('contact')">
          お問い合わせ
        </li>
      </template>

      <template v-else>
        <li>
          <a href="/#service">
            サービス
          </a>
        </li>
        <li>
          <a href="/#usage">
            ご利用方法
          </a>
        </li>
        <li>
          <a href="/#price">
            プラン・料金
          </a>
        </li>
        <li>
          <a href="/#example">
            活用事例
          </a>
        </li>
        <li>
          <a href="/#contact">
            お問い合わせ
          </a>
        </li>
      </template>

      <a target="_blank" href="https://corp.flew-createx.com/">
        <li><img class='base_image' src='@/assets/image/common/logo_flew_sm.png'></li>
      </a>
    </ul>
  </div>
</template>

<script>
import store from "@/store";
import {mapGetters} from "vuex";
import animation from "@/assets/js/functions/_core/animation";
import scroll from "@/assets/js/functions/_core/scroll";

export default {
  name: "SpMenuCover",

  mixins: [animation, scroll],

  props: {
    isInMypage: {
      type: Boolean,
    }
  },

  computed: {
    ...mapGetters({isMenuOpen: 'cover/isMenuOpen', pageName: 'page/getPageName'}),
  },

  methods: {
    hideMenuCover() {
      if (!this.isMenuOpen) return;
      store.dispatch('cover/toggleMenuCover')
    },
  }
}
</script>

<style lang="scss" scoped>
.sp-menu_cover {
  @include cover;
  @extend .fade_in;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  > ul {
    li {
      font-size: var(--huge2_font-size);
      line-height: var(--huge2_font-size);
      border-bottom: 2px solid $secondary-color;
      margin: 4rem 0;
      padding-bottom: 0.5rem;

      > .base_image {
        height: 3.5rem;
      }
    }
  }
}
</style>