<template>
  <div class="header_description-list">
    <div>
      <div class="left">
        <slot class="left" name="image"/>
      </div>
      <div class="right">
        <p>{{headerEnglish}}</p>
        <p><span>{{header}}</span></p>
      </div>
    </div>

    <p v-html="description" />
  </div>
</template>

<script>
  export default {
    name: "HeaderDescriptionList",

    props: {
      header: {
        type: String,
        required: true
      },
      headerEnglish: {
        type: String,
        required: true
      },
      description: {
        type: String,
        required: true
      },
    }
  }
</script>

<style lang="scss" scoped>
  .header_description-list {
    display: flex;
    flex-flow: column;

    > div {
      display: flex;
      justify-content: space-around;
      height: 10rem;
      background-color: $primary_accent-color;

      > .left {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40%;
        height: 100%;
        background-color: $fourth_accent-color;

        > .base_image {
          width: 6rem;
        }
      }

      > .right {
        width: 60%;
        text-align: center;
        align-self: center;

        > p {
          letter-spacing: 0.3rem;

          > span {
            letter-spacing: 1rem;
          }
        }
      }

    }

    > p {
      padding: 2rem;
      line-height: var(--huge1_font-size);
      min-height: 33rem;
      word-wrap: break-word;
      background-color: $white-color;
    }
  }
</style>
