/**
 * スクロールに関する定義をまとめたスクリプトファイル
 * 全テンプレートに読み込ませることで、全ての画面に適用できるようにする
 */

export default {
  mounted() {
    // ID指定でのスクロールジャンプ定義
    const hash = location.hash
    if (hash) {
      this.$nextTick(() => {
        this.scrollToHash(hash.replace('#', ''))
      })
    }

    // スクロール位置検知
    window.addEventListener('scroll', this.handleScroll)
  },

  watch: {
    '$route'() {
      const hash = location.hash
      if (hash) this.scrollToHash(hash.replace('#', ''))
    }
  },

  methods: {
    /**
     * 対象IDを持つ要素までスクロール
     *
     * @param id（'#'は除去して渡すこと）
     */
    scrollToHash(id) {
      // 対象の要素からバッファ分のpxを上に余白を持たせる
      // 対象要素が画面上部ギリギリに表示されることを防ぐ
      const buffer = 120

      // 必要データの確定
      const targetElement = document.getElementById(id)
      const rectTop = targetElement.getBoundingClientRect().top
      const offsetTop = window.pageYOffset
      const top = rectTop + offsetTop - buffer

      window.scrollTo({
        top,
        behavior: "smooth"
      });
    },
  }
}
