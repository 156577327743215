<template>
  <ul class="pc-menu_box sp_none">
    <template v-if="pageName === 'Top'">
      <li @click="scrollToHash('service')">
        <button>サービス</button>
      </li>
      <li @click="scrollToHash('usage')">
        <button>ご利用方法</button>
      </li>
      <li @click="scrollToHash('price')">
        <button>プラン・料金</button>
      </li>
      <li @click="scrollToHash('example')">
        <button>活用事例</button>
      </li>
      <li @click="scrollToHash('contact')">
        <button>お問い合わせ</button>
      </li>
    </template>

    <template v-else>
      <a href="/#service">
        <li>
          <button>サービス</button>
        </li>
      </a>
      <a href="/#usage">
        <li>
          <button>ご利用方法</button>
        </li>
      </a>
      <a href="/#price">
        <li>
          <button>プラン・料金</button>
        </li>
      </a>
      <a href="/#example">
        <li>
          <button>活用事例</button>
        </li>
      </a>
      <a href="/#contact">
        <li>
          <button>お問い合わせ</button>
        </li>
      </a>
    </template>

    <li>
      <a target="_blank" href="https://corp.flew-createx.com/">
        <img class='base_image' src='@/assets/image/common/logo_flew_sm.png'>
      </a>
    </li>
  </ul>
</template>

<script>
import {mapGetters} from "vuex";
import scroll from "@/assets/js/functions/_core/scroll"

export default {
  name: "PcMenuBox",

  computed: {
    ...mapGetters({pageName: 'page/getPageName'}),
  },

  mixins: [scroll],
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 768px) {
  .pc-menu_box {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    li {
      position: relative;
      transition: all .2s ease;
      margin: 0 2rem;

      > a > .base_image {
        height: 2.5rem;
      }
    }
  }
}
</style>
