<template>
  <div class="interview-block">
    <div class="contents">
      <div v-html="interview"/>
    </div>
  </div>
</template>

<script>
  export default {
    name: "InterviewBlock",

    props: {
      interview: {
        type: String,
        required: true
      }
    }
  }
</script>

<style lang='scss'>
  .interview-block {
    padding: 6rem 0;

    > .contents {
      @include contents;

      > div {
        > .huge3-label {
          display: flex;

          > .dash-icon {
            margin: 1.6rem 1rem 0 0;
          }
        }

        > p {
          .name-dm {
            margin-right: 1rem;
          }
        }
      }

      @media screen and (min-width: 768px) {
        > div {
          > .huge3-label {
            display: flex;
            align-items: center;

            > .dash-icon {
              margin-top: 0;
            }
         }
       }
      }

    }
  }
</style>
