<template>
  <div class="container">
    <TopHeader/>
    <SpMenuCover/>
    <router-view/>
    <Footer/>
  </div>
</template>

<script>
  import TopHeader from "@/components/organisms/common/layout/TopHeader";
  import Footer from "@/components/organisms/common/layout/Footer";
  import SpMenuCover from "@/components/organisms/common/cover/SpMenuCover";

  export default {
    name: 'MainTemplate',

    components: {Footer, TopHeader, SpMenuCover},
  }
</script>

<style lang="scss" scoped>
  .container {
    min-height: 100%;
    display: grid;
    grid-template-rows: 15vh 1fr 15vh;
    overflow: hidden;
  }

  .header {
    height: 15vh;
    grid-row: 1;
    position: fixed;
    z-index: 100;
  }

  .main {
    @include main;
    grid-row: 2;
  }

  .footer {
    grid-row: 3;
  }

  @media screen and (min-width: 768px) {
  }
</style>
