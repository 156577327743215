const state = {
    isMenuOpen: false,
};

const getters = {
  isMenuOpen: state => state.isMenuOpen,
};

const actions = {
  toggleMenuCover(context) {
    context.commit('toggleMenuCover');
  },
};

const mutations = {
  toggleMenuCover(state) {
    state.isMenuOpen = !state.isMenuOpen
  },
};

const cover = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default cover;
