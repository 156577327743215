<template>
  <div class="feature-block">
    <div class="contents">
      <h2 class="rectangle_label">flick 3つの特徴</h2>

      <div>
        <HeaderDescriptionList
          header-english="easy"
          header="楽々"
          description="窓口を弊社コンシェルジュに一本化。<br>
          作業者を探したり、作業者と予定調整をする手間が一切無くなります。 <br>
          同時に複数の作業者に依頼する時も、複数の作業者と直接やり取りする必要がないので、進捗管理の手間が激減します。"
        >
          <img class='base_image' slot='image' src='@/assets/image/pages/top/feature1.png'>
        </HeaderDescriptionList>

        <HeaderDescriptionList
          header-english="secure & trusted"
          header="安心"
          description="作業者は守秘義務契約書を交わした信頼できる人材のみを選抜しています。<br>
        また、同一作業者への再依頼リクエストもお受けいたしますので、案件ごとの作図表現を揃えることができます。"
        >
          <img class='base_image' slot='image' src='@/assets/image/pages/top/feature2.png'>
        </HeaderDescriptionList>

        <HeaderDescriptionList
          header-english="diversity"
          header="多種多様"
          description="CAD作業の他、簡単な構造計算やバックオフィス業務まで、日々の業務の中で生じる雑作業は何でもご相談ください。
          <br>一般的に対応が難しい業務につきましては、ご相談を頂いてから1営業日以内に受託可否のご回答をいたします。"
        >
          <img class='base_image' slot='image' src='@/assets/image/pages/top/feature3.png'>
        </HeaderDescriptionList>
      </div>
    </div>
  </div>
</template>

<script>
  import HeaderDescriptionList from "@/components/organisms/common/list/HeaderDescriptionList";

  export default {
    name: "WorkBlock",
    components: {HeaderDescriptionList}
  }
</script>

<style lang='scss' scoped>
  .feature-block {
    padding-bottom: 6rem;
    background-color: $third-color;

    > .contents {
      @include contents;

      > .rectangle_label {
        margin-bottom: 4rem;
        width: 100%;
      }
      > div {
        > .header_description-list {
          margin-bottom: 2rem;
        }
      }
      > p {
        width: 100%;
        text-align: center;
      }

      @media screen and (min-width: 768px) {
        > .rectangle_label {
          width: 60%;
        }

        > div {
          display: flex;
          justify-content: space-between;
          > .header_description-list {
            width: 32%;
          }
        }
      }
    }
  }
</style>