<template>
  <div class="usage-block">
    <div class="contents">
      <h2 class="rectangle_label">ご利用方法</h2>
      <p>ご依頼などの連絡にはビジネスチャットツールを使用いたします。</p>
      <p>「この作業ちょっとお願いしたいな」と思ったら<span class="orange-label">チャットでさっとご依頼ください。</span></p>
      <p>窓口は弊社のコンシェルジュに一元化しており、<span class="orange-label">作業者との調整や指示出しなど面倒なやり取りを一切省けるため</span>、本来やるべきお仕事に集中できます。</p>
      <br>
      <p>ご依頼の後は納期や概算所要時間をお知らせしますので、そちらをご確認頂ければ後は納品を待つだけ。</p>
      <p>できた時間を有効にご活用ください！</p>

      <img src="@/assets/image/pages/top/usage.png" class="base_image">
    </div>
  </div>
</template>

<script>
  export default {
    name: "UsageBlock"
  }
</script>


<style lang='scss' scoped>
  .usage-block {
    > .contents {
      @include contents;

      > .rectangle_label {
        margin-bottom: 4rem;
        width: 100%;
      }

      > .base_image {
        margin: 4rem 0;
        align-self: center;
      }

      @media screen and (min-width: 768px) {
        > .rectangle_label {
          width: 60%;
        }

        > .base_image {
          width: 55%;
        }
      }
    }
  }
</style>