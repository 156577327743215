<template>
  <div class="difference_staff-block">
    <div class="contents">
      <h2 class="rectangle_label">派遣社員、パート社員との違い</h2>
      <div>
        <div class="left">
          <div class=" title-dm">
            <h3 class="huge3_blue-label">派遣社員・パート社員</h3>
          </div>
          <br>
          <div>
            <div class="blue_check-icon"/>
            <p>一人が扱える業務範囲には限りがある</p>
            <img class='base_image arrow-dm sp_none' slot='image' src='@/assets/image/common/arrow_right.png'>
          </div>
          <div>
            <div class="blue_check-icon"/>
            <p>業務閑散期にも事務所に在籍するため<br>稼働率が下がる</p>
            <img class='base_image arrow-dm sp_none' slot='image' src='@/assets/image/common/arrow_right.png'>
          </div>
          <div>
            <div class="blue_check-icon"/>
            <p>人件費が固定費として発生する</p>
            <img class='base_image arrow-dm sp_none' slot='image' src='@/assets/image/common/arrow_right.png'>
          </div>
          <div>
            <div class="blue_check-icon"/>
            <p>教育に時間を要する</p>
            <img class='base_image arrow-dm sp_none' slot='image' src='@/assets/image/common/arrow_right.png'>
          </div>
          <div>
            <div class="blue_check-icon"/>
            <p>所内での人間関係に気を遣う</p>
            <img class='base_image arrow-dm sp_none' slot='image' src='@/assets/image/common/arrow_right.png'>
          </div>
        </div>

        <img class='base_image pc_none' slot='image' src='@/assets/image/common/arrow_down.png'>

        <div class="right">
          <div class=" title-dm">
            <img class='base_image' slot='image' src='@/assets/image/common/logo_background.png'>
          </div>
          <br>
          <div>
            <div class="orange_circle-icon two_line"/>
            <p>多様な人材を擁しているため、<br>幅広い業務に対応可能</p>
          </div>
          <div>
            <div class="orange_circle-icon two_line"/>
            <p>毎月の作業時間を調整可能なため、<br>必要な分だけ依頼ができて無駄がない</p>
          </div>
          <div>
            <div class="orange_circle-icon"/>
            <p>コストを変動費にできる</p>
          </div>
          <div>
            <div class="orange_circle-icon two_line"/>
            <p>教育の必要はありません<br>第一線で活躍する高スキル人材が多数在籍</p>
          </div>
          <div>
            <div class="orange_circle-icon two_line"/>
            <p>オンラインで完結<br>余計な気を遣う必要はありません</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "DifferenceStaffBlock"
  }
</script>

<style lang='scss' scoped>
  .difference_staff-block {
    > .contents {
      @include contents;

      > .rectangle_label {
        margin-bottom: 4rem;
        width: 100%;
      }

      > div {
        @include double_columns(49%, 49%);
        justify-content: space-between;
        margin-bottom: 4rem;

        > .base_image {
          align-self: center;
          margin: 2rem;
          width: 6rem;
          height: 6rem;
        }

        .title-dm {
          justify-self: center;
          width: 20rem;
          height: 6rem;
        }

        > div {
          padding: 2rem;

          > div {
            display: flex;
            > p {
              height: 8rem;
            }
          }
        }

        > .left {
          display: flex;
          flex-flow: column;
          background-color: $primary_accent-color;

          > .title-dm {
            align-items: center;
            width: 100%;
            height: 6rem;
          }

          > div {
            position: relative;
            > .arrow-dm {
              position: absolute;
              top: 1rem;
              right: -10rem;
              width: 12rem;
              height: 1.5rem;
            }

            > .blue_check-icon {
              align-self: flex-start;
              margin-top: 1rem;
              margin-bottom: 1rem;
              margin-right: 1rem;
            }
          }
        }

        > .right {
          display: flex;
          flex-flow: column;
          background-color: $third-color;

          > div > .orange_circle-icon {
            align-self: flex-start;
            margin-top: 1rem;
            margin-right: 1rem;

            &.two_line {
              align-self: flex-start;
              margin-top: 1rem;
            }
          }

          > .title-dm {
            align-items: center;
            width: 15rem;
            height: 6rem;
          }
        }
      }

      @media screen and (min-width: 768px) {
        > .rectangle_label {
          width: 60%;
        }
        > div > div {
          padding: 2rem 2rem 2rem 10rem;

          > .round_blue-label, > .round_orange-label {
            align-items: center;
            width: 70%;
          }
        }
      }
    }
  }
</style>