<template>
  <div class="description-block">
    <div class="contents">
      <p>株式会社ディックス</p>
      <p class="small2-label">構造設計部部長 取締役 田村尚土（たむらなおと）様</p>
      <p class="small2-label">1987年設立、愛知県名古屋市に本社を置く株式会社ディックス。構造設計に付随する細かな業務を、flickのアシスタントに依頼している。</p>
      <p class="small2-label">社員数（規模）：15名／業務内容：施工図業務、仮設図業務、現場管理支援、情報技術業務、構造設計業務、コンピュテーショナルデザイン業務</p>

      <p class="clear_rectangle_blue-label">課題</p>
      <div class="list-dm">
        <div class="blue_circle-icon"/>
        <p>外注に依頼する際、業務範囲外の作業を依頼する際に<span class="blue-label">手間が発生</span>する</p>
      </div>
      <div class="list-dm">
        <div class="blue_circle-icon"/>
        <p>最低限の人数で業務を進めているため、<span class="blue-label">急な依頼が入るとすぐに対応できない</span></p>
      </div>
      <div class="list-dm">
        <div class="blue_circle-icon"/>
        <p><span class="blue-label">業務を拡大</span>していきたい</p>
      </div>

      <p class="clear_rectangle_orange-label">解決策</p>

      <div class="list-dm">
        <div class="orange_circle-icon"/>
        <p>メインの業務に付随する細かい業務をflickに依頼した</p>
      </div>
      <div class="list-dm">
        <div class="orange_circle-icon two_line"/>
        <p>大量の業務や修正作業を依頼された際に、<span class="orange-label">期限内に納品できるよう人員を確保</span>してもらった
          <br>（多様な業務に対応できるようアシスタントを確保している。業務内容に応じた人材を手配できるため、<br><span class="orange-label">突発的に生じる作業にも対応</span>できる）</p>
      </div>

      <div class="effect-dm">
        <p class="huge3_red_wide-label">効果</p>
        <p>1. 業務コントロールができ、<span class="red-label">従来より業務の依頼がフレキシブルに対応してもらえる</span>ようになった</p>
        <p>2. 社員の<span class="red-label">長時間勤務を防ぐ</span>ことができた。</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "DescriptionBlock"
  }
</script>

<style lang='scss' scoped>
  .description-block {
    > .contents {
      @include contents;

      > .clear_rectangle_orange-label, > .clear_rectangle_blue-label {
        margin: 6rem 0 2rem 0;
        width: 100%;
      }

      > .list-dm {
        display: flex;
        margin-left: 2rem;

        > .orange_circle-icon, > .blue_circle-icon {
          align-self: center;
          margin-right: 1rem;

          &.two_line {
            align-self: flex-start;
            margin-top: 1rem;
          }
        }
      }

      @media screen and (max-width: 767px) {
        > .effect-dm {
          margin-top: 6rem;
          padding: 2rem;
          width: 100%;
          background-image: url("../../../../../assets/image/pages/example/dix/effect.png");
          background-position: center center;
          background-size: cover;
          background-repeat: no-repeat;

          > .huge3_red_wide-label {
            margin-bottom: 2rem;
          }
        }
      }

      @media screen and (min-width: 768px) {
        > .effect-dm {
          margin-top: 6rem;
          padding: 3rem 6rem;
          width: 100%;
          min-height: 20rem;
          background-image: url("../../../../../assets/image/pages/example/dix/effect.png");
          background-position: center center;
          background-size: contain;
          background-repeat: no-repeat;

          > .huge3_red_wide-label {
            margin-bottom: 2rem;
          }
        }
      }
    }
  }
</style>