<template>
  <div class="meeting-block">
    <div class="contents">
      <h2 class="rectangle_label">指示資料の作成が面倒！ そんな時は・・・</h2>
      <p>flickではコンシェルジュへ作業のご依頼を頂く際に、<span class="orange-label">ウェブ会議形式で打合せをさせて頂き、その打合せを録画したビデオを作業者と共有する方法を取ることも可能です。</span></p>
      <p>これにより、口頭で十分伝わるような作業指示のために、指示資料を作成する手間が省け、 なおかつ伝達の齟齬を大幅に減らすことができます。</p>

      <img src="@/assets/image/pages/top/meeting.png" class="base_image">
    </div>
  </div>
</template>

<script>
  export default {
    name: "MeetingBlock",
  }
</script>

<style lang='scss' scoped>
  .meeting-block {
    padding-bottom: 6rem;
    background-color: $third-color;

    > .contents {
      @include contents;

      > .rectangle_label {
        margin-bottom: 4rem;
        width: 100%;
      }

      > p {
        width: 100%;
      }

      > .base_image {
        margin: 4rem 0;
        align-self: flex-end;
      }

      @media screen and (min-width: 768px) {
        > .rectangle_label {
          width: 60%;
        }

        > p {
          width: 80%;
        }

        > .base_image {
          margin-top: 0;
          width: 30%;
        }
      }
    }
  }
</style>