<template>
  <div class="circle-list">
    <ul>
      <li v-for="(item, index) in items" :key="index">
        <div class="orange_circle-icon"></div>
        <p>{{item}}</p>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: "CircleList",

    props: {
      items: {
        type: Array,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .circle-list {
    display: flex;
    flex-flow: column;

    > ul {
      padding: 2rem;
      background-color: $white-color;

      > li {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        > div {
          margin-right: 1rem;
        }
      }
    }
  }
</style>
