<template>
  <div class="work-block">
    <div class="contents">
      <h2 class="rectangle_label">受託可能業務</h2>

      <div>
        <HeaderCircleList
          header="事務系"
          circle-color="pink"
          :items="['請求書などの書類送付作業', '会食場所のリサーチ・予約', 'Excelデータ入力作業', 'Powerpointのデザイン調整', 'その他Office関連業務', 'CADデータの変換作業', '会議テープの文字起こし', 'PDF文字起こし', '翻訳']"
        >
          <img class='base_image' slot='image' src='@/assets/image/pages/top/work1.png'>
        </HeaderCircleList>

        <HeaderCircleList
          header="意匠系・施工系"
          circle-color="yellow"
          :items="['CAD作図業務全般', '面積、駐車台数などの算出', '施工図整合チェック', 'パース作成', 'ウォークスルー動画作成', 'プレゼン資料デザイン調整', '建築確認申請業務代行（要ご相談）']"
        >
          <img class='base_image' slot='image' src='@/assets/image/pages/top/work2.png'>
        </HeaderCircleList>

        <HeaderCircleList
          header="構造系"
          circle-color="green"
          :items="['CAD作図業務全般', '簡易な構造計算', '解析モデル作成', '施工図整合チェック', 'Excelデータ入力作業', '計算書印刷・お届け']"
        >
          <img class='base_image' slot='image' src='@/assets/image/pages/top/work3.png'>
        </HeaderCircleList>
      </div>

      <p>他にも切り出したい作業があればお気軽にご相談ください！</p>
    </div>
  </div>
</template>

<script>
  import HeaderCircleList from "@/components/organisms/common/list/HeaderCircleList";

  export default {
    name: "WorkBlock",
    components: {HeaderCircleList}
  }
</script>

<style lang='scss' scoped>
  .work-block {
    padding-bottom: 6rem;
    background-color: $third-color;

    > .contents {
      @include contents;

      > .rectangle_label {
        margin-bottom: 4rem;
        width: 100%;
      }
      > div {
        > .header_circle-list {
          margin-bottom: 2rem;
        }
      }
      > p {
        width: 100%;
        text-align: center;
      }

      @media screen and (min-width: 768px) {
        > .rectangle_label {
          width: 60%;
        }

        > div {
          display: flex;
          justify-content: space-between;
          > .header_circle-list {
            width: 30%;
          }
        }
      }
    }
  }
</style>