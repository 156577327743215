<template>
  <div class="example-box" :class="isMock ? 'mock' : ''">
    <template v-if="isMock">
      <img class="mock-dm" src='@/assets/image/pages/top/example_mock.png'>
    </template>

    <template v-else>
      <slot name="image"/>

      <div>
        <slot name="logo"/>
      </div>

      <div class="text-dm" v-html="description"/>
    </template>
  </div>
</template>

<script>
  export default {
    name: "ExampleBox",

    props: {
      isMock: {
        type: Boolean,
        required: true
      },
      description: {
        type: String,
        required: false
      },
    }
  }
</script>

<style lang="scss">
  .example-box {
    display: flex;
    flex-flow: column;
    width: 100%;
    max-width: 100%;
    height: 50rem;
    box-shadow: 0.4rem 0.5rem 0.5rem 0.5rem $light-gray-color;

    > div {
      display: flex;
      flex-flow: column;
      width: 100%;
      background-color: $third-color;

      > .logo-dm {
        align-self: center;
        justify-self: center;
        padding: 2rem 0;
        width: 50%;
      }
    }

    > .mock-dm {
      width: 100%;
      height: 100%;
    }

    > .text-dm {
      padding: 2rem;
      word-wrap: break-word;
      background-color: $white-color;

      > p {
        margin-bottom: 2rem;
      }
    }
  }
</style>
