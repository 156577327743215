import Vue from 'vue'

export default {
  methods: {
    showSuccessMessage(message) {
      const options = {
        position: "bottom-center",
        duration: 3000,
        type: "success"
      };

      Vue.toasted.show(message, options);
    },

    showErrorMessage(message) {
      const options = {
        position: "bottom-center",
        duration: 3000,
        type: "error"
      };

      Vue.toasted.show(message, options);
    },
  }
}
