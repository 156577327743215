const state = {
  pageName: '',
};

const getters = {
  getPageName: state => state.pageName,
};

const actions = {
  setPageName(context, pageName) {
    context.commit('setPageName', pageName);
  },
};

const mutations = {
  setPageName(state, pageName) {
    state.pageName = pageName
  },
};

const page = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default page;
