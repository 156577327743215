import firebase from "firebase";
import logging from '@/assets/js/functions/_core/logging';
import store from "@/store";

export default {
  mixins: [logging],

  methods: {
    /**
     * 非同期通信設定（method, param）をし、実行する
     *
     * @param method onCallのメソッド名
     * @param requestParams パラメータ
     * @returns {Promise<*>}
     */
    async callFunction(method, requestParams) {
      const functions = firebase.app().functions('asia-northeast1');
      const func = functions.httpsCallable(method);
      let result;

      await func(requestParams).then(response => {
        if (!response || !response.data || response.data.status === 500) throw response;

        result = response.data;
      }).catch(error => {
        store.dispatch('cover/toggleLoadCover');
        this.logger(error, "アクションが失敗しました。", {params: requestParams});
        throw error;
      });

      return result;
    },
  }
}
