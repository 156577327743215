<template>
  <div class="voice-block">
    <div class="contents">
      <p class="huge3_wide-label" v-html="voice"/>
    </div>
  </div>
</template>

<script>
  export default {
    name: "VoiceBlock",

    props: {
      voice: {
        type: String,
        required: true
      }
    }
  }
</script>

<style lang='scss' scoped>
  .voice-block {
    padding: 6rem 0;
    background-color: $third-color;
    > .contents {
      @include contents;
    }
  }
</style>
