<template>
  <div class="top-block">
    <img src="@/assets/image/pages/top/title1.png" class="base_image title1_dm">
    <img src="@/assets/image/pages/top/title2.png" class="base_image title2_dm">
    <img src="@/assets/image/pages/top/title3.png" class="base_image title3_dm">
  </div>
</template>

<script>
  export default {
    name: "TopBlock"
  }
</script>

<style lang='scss' scoped>
  .top-block {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-image: url("../../../../assets/image/pages/top/top.png");

    .base_image {
      width: 80%;
    }
    .title2_dm {
      margin: 3rem 0;
    }

    @media screen and (min-width: 768px) {
      .title1_dm {
        width: 55%;
      }
      .title2_dm {
        width: 45%;
        margin: 3rem 10% 3rem 0;
      }
      .title3_dm {
        width: 50%;
        margin-right: 5%;
      }
    }
  }
</style>