import Vue from 'vue'
import Vuex from "vuex"

import cover from "./cover";
import page from "./page";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    cover,
    page,
  },
});
export default store
