<template>
  <div class="difference_work-block">
    <div class="contents">
      <h2 class="rectangle_label">従来の下請負型の外注との違い</h2>
      <div>
        <div class="left">
          <div class=" title-dm">
            <h3 class="huge3_blue-label">従来の下請負型の外注</h3>
          </div>
          <br>
          <p class="blue-label">【理由】</p>
          <div>
            <div class="blue_check-icon"/>
            <p>余剰人材を抱えないため</p>
          </div>
          <div>
            <div class="blue_check-icon"/>
            <p>不足人材を補うため</p>
          </div>
          <br>
          <p class="blue-label">【結果】</p>
          <div>
            <div class="blue_check-icon two_line"/>
            <p>自社のキャパシティを超える<br>部分を外に流す</p>
          </div>
          <img class='base_image arrow-dm' slot='image' src='@/assets/image/common/arrow_down_blue.png'>
          <div class="round_blue-label">オーバーフロー型の外注</div>
        </div>

        <div class="right">
          <div class=" title-dm">
            <img class='base_image' slot='image' src='@/assets/image/common/logo_background.png'>
          </div>
          <br>
          <p class="orange-label">【理由】</p>
          <div>
            <div class="orange_circle-icon two_line"/>
            <p>所員が本来やるべき<br>コアな作業に集中するため</p>
          </div>
          <br>
          <p class="orange-label">【結果】</p>
          <div>
            <div class="orange_circle-icon"/>
            <p>既存の人材リソースで生産性向上</p>
          </div>
          <div>
            <div class="orange_circle-icon"/>
            <p>所員の意欲・充実感アップ</p>
          </div>
          <img class='base_image arrow-dm' slot='image' src='@/assets/image/common/arrow_down.png'>
          <div class="round_orange-label">効率UP型の外注</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "DifferenceWorkBlock"
  }
</script>

<style lang='scss' scoped>
  .difference_work-block {
    > .contents {
      @include contents;

      > .rectangle_label {
        margin-bottom: 4rem;
        width: 100%;
      }

      > div {
        @include double_columns(49%, 49%);
        justify-content: space-between;
        margin-bottom: 4rem;

        .title-dm {
          justify-self: center;
          width: 20rem;
          height: 6rem;
        }

        > div {
          padding: 2rem;

          > div {
            display: flex;
          }

          > .arrow-dm {
            align-self: center;
            width: 4rem;
            height: 4rem;
            margin: 2rem 0;
          }
        }

        > .left {
          display: flex;
          flex-flow: column;
          background-color: $primary_accent-color;

          > .title-dm {
            align-items: center;
            width: 100%;
            height: 6rem;
          }

          > div > .blue_check-icon {
            align-self: center;
            margin-bottom: 1rem;
            margin-right: 1rem;

            &.two_line {
              align-self: flex-start;
              margin-top: 1rem;
            }
          }
        }

        > .right {
          display: flex;
          flex-flow: column;
          background-color: $third-color;

          > div > .orange_circle-icon {
            align-self: center;
            margin-right: 1rem;

            &.two_line {
              align-self: flex-start;
              margin-top: 1rem;
            }
          }

          > .title-dm {
            align-items: center;
            width: 15rem;
            height: 6rem;
          }
        }
      }

      @media screen and (min-width: 768px) {
        > .rectangle_label {
          width: 60%;
        }
        > div > div {
          padding: 2rem 2rem 2rem 10rem;

          > .arrow-dm {
            margin: 2rem 10rem 2rem 0;
          }

          > .round_blue-label, > .round_orange-label {
            align-items: center;
            width: 70%;
          }
        }
      }
    }
  }
</style>