<template>
  <div class="example-block">
    <div class="contents">
      <h2 class="rectangle_label">活用事例</h2>
      <div class="box-dm">

        <router-link :to="{name: 'Dix'}">
          <ExampleList
            :is-mock="false"
            description="
            <p class='huge3_bold-label'>株式会社ディックス 様</p>
            <p class='bold-label'>業務コントロールができ、社員の長時間勤務を防止</p>
            <p class='small2-label'>社員数：15名 / 業務内容：施工図業務、<br>情報技術業務、構造設計業務 他</p>">
            <img slot='image' class="base_image" src='@/assets/image/pages/top/example_dix_image_sm.png'>
            <img slot='logo' class="base_image logo-dm" src='@/assets/image/pages/top/example_dix_logo.png'>
          </ExampleList>
        </router-link>
        <ExampleList :is-mock="true"/>
        <ExampleList :is-mock="true"/>
      </div>
    </div>
  </div>
</template>

<script>
  import ExampleList from "@/components/organisms/common/box/ExampleBox";
  export default {
    name: "ExampleBlock",
    components: {ExampleList},
  }
</script>

<style lang='scss' scoped>
  .example-block {
    > .contents {
      @include contents;

      > .rectangle_label {
        margin-bottom: 4rem;
        width: 100%;
      }

      > .box-dm {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
        grid-gap: 2rem;
        padding: 2rem;
        > .example-box {
          margin-bottom: 2rem;
        }
      }

      @media screen and (min-width: 768px) {
        > .rectangle_label {
          width: 60%;
        }
      }
    }
  }
</style>
