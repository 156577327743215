<template>
  <div class="title-block">
    <div class="contents">
      <h2 class="rectangle_label">活用事例</h2>
    </div>
  </div>
</template>

<script>
  export default {
    name: "TitleBlock"
  }
</script>

<style lang="scss" scoped>
  .title-block {
    > .contents {
      @include contents;

      > .rectangle_label {
        width: 100%;
      }

      @media screen and (min-width: 768px) {
        > .rectangle_label {
          width: 60%;
        }
      }
    }
  }
</style>
