<template>
  <header class="header" id="top">
    <router-link to='/'>
      <img class="base_image" src="@/assets/image/common/logo_background.png"/>
    </router-link>

    <!-- PC用 -->
    <PcMenuBox/>

    <!-- SP用 -->
    <HamburgerMenuButton/>
  </header>
</template>

<script>
  import HamburgerMenuButton from "@/components/atoms/HamburgerMenuButton";
  import PcMenuBox from "@/components/organisms/common/box/PcMenuBox";

  export default {
    name: "TopHeader",

    components: {PcMenuBox, HamburgerMenuButton}
  }
</script>

<style lang="scss" scoped>
  .header {
    background-color: $white-color;
    display: flex;
    padding-bottom: 0.5rem;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    > a > .base_image {
      display: flex;
      align-items: center;
      margin-left: 4rem;
      width: 40%;
      height: 40%;
    }
  }

  @media screen and (max-width: 767px) {
    .header {
      position: relative;

      .menu_box {
        position: absolute;
        top: 100%;
        right: 0;
        transition: all 1s;
        transform: translate(100%, 0);
        opacity: 0;
      }

      .menu_box.active {
        transition: all 1s;
        transform: translate(0, 0);
        opacity: 1;
      }

      > .hamburger-menu_button {
        min-width: 15%;
      }
    }

  }

  @media screen and (min-width: 768px) {
    .header {
      padding-left: 1rem;
    }
    .header .left_image {
      height: 90%;
    }

    .header .menu_box {
      max-width: 50%;
    }
  }
</style>
