import Vue from 'vue';

import App from './App.vue';
import router from './router';
import store from './store';
import Toasted from 'vue-toasted';
import firebase from "./firebase";
import sentry from './sentry';

Vue.config.productionTip = false;

firebase.init();

new Vue({
  render: h => h(App),
  router,
  store,
  sentry,
}).$mount('#app');

require('@/assets/css/_base/main.scss');

// include the default style

// Tell Vue to install the plugin.
Vue.use(Toasted);
