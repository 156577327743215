<template>
  <div class="service-block">
    <div class="contents">
      <h2 class="rectangle_label">サービス</h2>
      <p>設計事務所や建設現場事務所の中で、やむを得ず所員が行っていた <span class="orange-label">“ちょっとした”</span> 作業をオンライン上で手軽に依頼できるサービスです。</p>
      <div>
        <div class="left">
          <p>いつでも、多様な作業を引き受ける</p><br>
          <p class="huge2_primary-label">スーパーアシスタント</p>
        </div>
        <div class="right">
          <img src="@/assets/image/pages/top/super_assistant.png" class="base_image">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ServiceBlock"
  }
</script>

<style lang='scss' scoped>
  .service-block {
    > .contents {
      @include contents;

      > .rectangle_label {
        margin-bottom: 4rem;
        width: 100%;
      }

      > div {
        @include double_columns(40%, 60%);
        margin-top: 4rem;

        > .left {
          > .huge2_primary-label {
            margin: 4rem 0;
          }
        }
      }

      @media screen and (min-width: 768px) {
        > .rectangle_label {
          width: 60%;
        }
      }
    }
  }
</style>