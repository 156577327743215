<template>
  <div class="header_circle-list">
    <div>
      <p class="left">{{header}}</p>
      <slot class="right" name="image"/>
    </div>
    <ul>
      <li v-for="(item, index) in items" :key="index">
        <div v-if="circleColor === 'pink'" class="pink_circle-icon"></div>
        <div v-if="circleColor === 'green'" class="green_circle-icon"></div>
        <div v-if="circleColor === 'yellow'" class="yellow_circle-icon"></div>
        <p>{{item}}</p>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: "HeaderCircleList",

    props: {
      header: {
        type: String,
        required: true
      },
      circleColor: {
        type: String,
        required: true
      },
      items: {
        type: Array,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .header_circle-list {
    display: flex;
    flex-flow: column;

    > div {
      display: flex;
      justify-content: space-around;
      padding: 2rem 0;
      background-color: $primary_accent-color;

      > p {
        width: 60%;
        letter-spacing: 1rem;
        text-align: center;
        align-self: center;
      }

      > .base_image {
        height: 7rem;
      }
    }

    > ul {
      background-color: $white-color;

      > li {
        display: flex;
        align-items: flex-start;
        margin: 0 1rem 1rem 2rem;

        > div {
          margin:  1.2rem 1rem 0 0;
        }
      }
    }
  }
</style>
