<template>
  <div class="discount-block">
    <div class="contents">
      <h2 class="rectangle_label">事務系作業の割引</h2>
      <p>以下の作業については、要した作業時間を0.7掛けして当月の残時間から差し引きます。</p>

      <div>
        <CircleList
          :items="['請求書などの書類送付作業', '会食場所のリサーチ・予約', 'Excelデータ入力作業', 'Powerpointのデザイン調整', 'その他Office関連業務', 'CADデータの変換作業', '会議テープの文字起こし', 'PDF文字起こし']"
        />

        <img class='base_image sp_none' slot='image' src='@/assets/image/common/arrow_right.png'>
        <img class='base_image pc_none' slot='image' src='@/assets/image/common/arrow_down.png'>

        <div class="round_orange-label"><span class="huge3-label">作業時間</span> <span class="huge3_primary-label">× 0.7</span></div>
      </div>
    </div>
  </div>
</template>

<script>
  import CircleList from "@/components/organisms/common/list/CircleList";

  export default {
    name: "DiscountBlock",
    components: {CircleList},
  }
</script>

<style lang='scss' scoped>
  .discount-block {
    padding-bottom: 6rem;
    background-color: $third-color;

    > .contents {
      @include contents;

      > .rectangle_label {
        margin-bottom: 4rem;
        width: 100%;
      }

      > p {
        margin: 2rem 0;
        width: 100%;
      }

      > div {
        display: flex;
        width: 100%;

        > .round_orange-label {
          width: 60%;
        }
      }

      @media screen and (max-width: 767px) {
        > div {
          flex-flow: column;
          align-items: center;

          .circle-list {
            width: 100%;
          }

          > .base_image {
            margin: 2rem;
            width: 4rem;
            height: 6rem;
          }
        }
      }

      @media screen and (min-width: 768px) {
        > .rectangle_label {
          width: 60%;
        }

        > p {
          width: 80%;
        }

        > div {
          .circle-list {
            width: 100%;
          }

          > .base_image {
            position: relative;
            top: 20rem;
            left: -4rem;
            margin: 2rem;
            width: 10rem;
            height: 4rem;
          }

          > .round_orange-label {
            position: relative;
            top: 20rem;
            height: max-content;
          }
        }
      }
    }
  }
</style>