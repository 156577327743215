import {mapGetters} from 'vuex';
import toast from '@/assets/js/functions/_core/toast';
import url from "@/assets/js/functions/_core/url";
import * as Sentry from '@sentry/browser';

export default {
  mixins: [toast, url],

  computed: {
    ...mapGetters({uid: 'auth/uid', isLogin: 'auth/isLogin'}),
  },

  methods: {
    /**
     * 開発環境と本番用のログ処理
     *
     * @param error 例外
     * @param message 任意のメッセージ
     * @param customData 任意のデータ
     */
    logger(error, message, customData=null) {
      this.showErrorMessage(message);
      if (this.isDevelopment()) {
        console.error(error);
        this.developmentLogger(message, customData);
      } else {
        this.productionLogger(error, message, customData);
      }
    },

    developmentLogger(message, customData) {
      console.log('message: ' + message);
      console.log(customData);
      console.log('user: ' + this.uid);
      console.log(this.isLogin);
    },

    productionLogger(error, message, customData) {
      // eslint-disable-next-line no-undef
      Sentry.configureScope((scope) => {
        scope.setUser({
          id: this.uid,
          isLogin: this.isLogin,
          message: message,
          customData: customData
        });
      });

      // eslint-disable-next-line no-undef
      Sentry.captureException(error);
    },
  }
}
