<template>
  <div class="contact-block">
    <div class='contents'>
      <h2 class="rectangle_label">お問い合わせ</h2>
      <InquiryBox/>
    </div>
  </div>
</template>

<script>
  import InquiryBox from "@/components/organisms/common/box/InquiryBox.vue";

  export default {
    name: 'ContactBlock',
    components: {InquiryBox},
  }
</script>

<style lang="scss" scoped>
  .contact-block {
    > .contents {
      @include contents;

      > .rectangle_label {
        margin-bottom: 4rem;
        width: 100%;
      }

      > .inquiry_box {
        margin-bottom: 2rem;
      }

      @media screen and (min-width: 768px) {
        > .rectangle_label {
          width: 60%;
        }
      }
    }
  }
</style>